
  .container {
    font-size: 14px;
    max-width: 960px;
  }
  
  .pricing-header {
    max-width: 700px;
    
  }

  .card-header{
    background-color: #233448;
    text-align: center;
  }

  .card-body{
    background-color: #1a2837;
  }

  .card-body button{
    background-color: #696d7f;
    border-width: 0; 
  }


  .center-container{
      display: flex;
      justify-content: center;
      max-width: 85%
  }
  
  
  .card {
    border-width: 0;
  }
    

  .box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
  
  .leaguebot-logo{
    width: auto;
    height: 80px;
    display: block;
    margin-bottom: 45px;
  }

  .list-unstyled li{
    font-size: medium;
  }


  .pricing-signup-button{
    background-color: #72bfff;
    border-color: #72bfff;
    color: #1a2837;
  }